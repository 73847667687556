.experience-suzano_layouts-roundedBrands{
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.brandsCarouselHeading {
    display: flex;
    h2 {
        color: #23346c;
        font-size: 23px;
        font-family: "Open Sans" !important;
        font-weight: 700;
        margin-bottom: 24px;
    }
}

@media (max-width: 991px) {
    .brandsCarouselHeading {
        h2 {
            text-align: center;
        }
    }
}

@media (max-width: 576px) {
    .brandsCarouselHeading {
        h2 {
            font-size: 1.2rem;
            margin-bottom: 12px;
        }
    }
}